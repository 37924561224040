import React, { FormEvent, useEffect, useState } from "react";
import "./report.scss";
import Calendar from "react-calendar";
import { api } from "../../../../utilities/core";
import { useSelector } from "react-redux";
import { RootState } from "../../../..";
import { TransactionDoc } from "../../../../types";
import moment from "moment";
import Loader from "../../../../utilities/Loader";
function Transactions() {
  const [startDate, setStartDate] = useState(new Date());
  const [sort, setSort] = useState<any>({});
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState<TransactionDoc[]>([]);
  const [transactionSummary, setTransactionSummary] = useState({
    quantityOfProducts: 0,
    totalProfit: 0,
    discountTotal: 0,
    transactionsTotal: 0,
  });
  let [selectedTransaction, setSelectedTransaction] =
    useState<TransactionDoc>();
  const { entity } = useSelector((state: RootState) => state);
  let [query, setQuery] = useState("");
  const handleStartChange = async (value: any) => {
    setStartDate(value);
    // handleFetch(value, endDate);
  };
  const handleEndChange = async (value: any) => {
    setEndDate(value);
    handleFetch(startDate, value);
  };
  const handleFetch = async (
    startDate: Date,
    endDate: Date,
    srt: any = sort
  ) => {
    setLoading(true);
    let start = moment(startDate).startOf("day").toISOString();
    let end = moment(endDate).endOf("day").toISOString();
    try {
      let req = await fetch(api + `/sales/${query}`, {
        method: "POST",
        body: JSON.stringify({ start, end, sort: srt }),
        headers: {
          Authorization: "Bearer " + entity?.token,
          "Content-type": "application/json",
        },
      });
      if (req.ok) {
        let res = await req.json();
        setTransactions([]);
        setTransactions(res.transactions);
        delete res.transactions;
        setTransactionSummary(res);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    handleFetch(startDate, endDate);
  };
  const handleSort = (key: string) => {
    let newSort = sort[key]
      ? sort[key] === 1
        ? { [key]: -1 }
        : { [key]: 1 }
      : { [key]: 1 };
    return () => {
      setSort(newSort);
      console.log(newSort);
      handleFetch(startDate, endDate, newSort);
    };
  };
  useEffect(() => {
    handleFetch(startDate, endDate);
    console.log(sort);
  }, []);
  return (
    <div className="transactions-con">
      {selectedTransaction?.invoiceNumber && (
        <div className="transactions-con__dialog">
          <div className="transactions-con__dialog__invoice">
            <p
              onClick={() => setSelectedTransaction(undefined)}
              className="close"
            >
              X
            </p>
            <h2>{selectedTransaction?.invoiceNumber}</h2>
            <div className="transactions-con__dialog__invoice__table">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Qty</th>
                    <th>Price</th>
                    <th>Discount</th>
                    <th>Sub-Total</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedTransaction.items.map((transaction, index) => (
                    <tr key={transaction._id}>
                      <td>{index + 1}</td>
                      <td>{transaction?.name}</td>
                      <td>{transaction?.quantity?.toLocaleString()}</td>
                      <td>&#8358;{transaction.sell_price.toLocaleString()}</td>
                      <td>-&#8358;{transaction.discount.toLocaleString()}</td>
                      <td
                        style={{
                          textDecoration:
                            transaction.discount > 0 ? "line-through" : "",
                        }}
                      >
                        &#8358;{transaction.total.toLocaleString()}
                      </td>
                      <td>
                        &#8358;
                        {(
                          transaction.total - transaction.discount
                        ).toLocaleString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="transactions-con__dialog__invoice__detail">
              <p>Mode of Payment</p>
              <p>{selectedTransaction.mop}</p>
            </div>

            <div className="transactions-con__dialog__invoice__detail">
              <p>Sub-total</p>
              <p>
                &#8358;
                {(
                  selectedTransaction.total + selectedTransaction.discount
                ).toLocaleString()}
              </p>
            </div>
            <div className="transactions-con__dialog__invoice__detail">
              <p>Discount</p>
              <p>-&#8358;{selectedTransaction.discount}</p>
            </div>
            <h1>&#8358;{selectedTransaction.total}</h1>
          </div>
        </div>
      )}
      <div className="transactions-con__section-one">
        <div className="transactions-con__section-one__calendar-con">
          <Calendar onChange={handleStartChange} value={startDate} />
          <p>Start Date</p>
        </div>
        <div className="transactions-con__section-one__calendar-con">
          <Calendar onChange={handleEndChange} value={endDate} />
          <p>End Date</p>
        </div>

        <form
          onSubmit={handleSubmit}
          className="transactions-con__section-one__search-bar"
        >
          <input
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Product name, invoice number, invoice total"
            type="search"
            name="search"
            id=""
          />
          <button>Search</button>
        </form>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="transactions-con__table">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Invoice Number</th>
                  <th onClick={handleSort("items")}>No of Items</th>
                  <th onClick={handleSort("discount")}>Discount</th>
                  <th onClick={handleSort("mop")}>MOP</th>
                  <th onClick={handleSort("createdAt")}>Time</th>
                  <th>Date</th>
                  <th onClick={handleSort("total")}>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr className="bottom">
                  <td>Total</td>
                  <td></td>
                  <td>
                    {transactionSummary.quantityOfProducts?.toLocaleString()}{" "}
                    units
                  </td>
                  <td>
                    &#8358;{transactionSummary.discountTotal?.toLocaleString()}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    &#8358;
                    {transactionSummary.transactionsTotal?.toLocaleString()}
                  </td>
                </tr>
                {transactions.map((transaction, index) => (
                  <tr
                    key={transaction._id}
                    onClick={() => setSelectedTransaction(transaction)}
                  >
                    <td>{index + 1}</td>
                    <td>{transaction.invoiceNumber}</td>
                    <td>{transaction?.items?.length.toLocaleString()}</td>
                    <td>{transaction?.discount.toLocaleString()}</td>
                    <td>{transaction?.mop}</td>
                    <td>
                      {moment.utc(transaction.createdAt).format("hh:mm:ss")}
                    </td>
                    <td>
                      {moment(transaction.createdAt).format("DD-MMM-YYYY")}
                    </td>
                    <td>&#8358;{transaction.total.toLocaleString()}</td>
                  </tr>
                ))}
                <tr className="bottom">
                  <td>Total</td>
                  <td></td>
                  <td>
                    {transactionSummary.quantityOfProducts?.toLocaleString()}{" "}
                    units
                  </td>
                  <td>
                    &#8358;{transactionSummary.discountTotal?.toLocaleString()}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    &#8358;
                    {transactionSummary.transactionsTotal?.toLocaleString()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="transactions-con__detail-con">
            <div className="transactions-con__detail">
              <p>Average Trx</p>
              <p>
                {(
                  transactionSummary.transactionsTotal / transactions.length
                ).toLocaleString()}
              </p>
            </div>

            <div className="transactions-con__detail">
              <p>Profit</p>
              <p>&#8358;{transactionSummary.totalProfit?.toLocaleString()}</p>
            </div>
            <div className="transactions-con__detail">
              <p>%Profit</p>
              <p>
                {(
                  (100 * transactionSummary.totalProfit) /
                  transactionSummary.transactionsTotal
                ).toLocaleString()}
                %
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Transactions;
