import { createSlice } from "@reduxjs/toolkit";
import { ProductDoc } from "../types";
export interface ICategory {
  category: string;
  color: string;
  showOnTill: string;
  entityId: string;
  _id: string;
  products: ProductDoc[];
}
let hasCategories = localStorage.getItem("categories");
let categories: ICategory[] = hasCategories ? JSON.parse(hasCategories) : [];
const categorySlice = createSlice({
  initialState: categories,
  name: "categories",
  reducers: {
    setCategories: (state, { payload }) => {
      localStorage.setItem("categories", JSON.stringify(payload));
      return payload;
    },
    addCategory: (state, { payload }) => {
      let categories: ICategory[] = [...state, payload].sort((a, b) =>
        a.category > b.category ? 1 : a.category < b.category ? -1 : 0
      );
      localStorage.setItem("categories", JSON.stringify(categories));
      return categories;
    },
    updateCategory: (state, { payload }) => {
      let categories: ICategory[] = [...state];
      payload.category.products = state[payload.index].products;
      categories[payload.index] = payload.category;
      localStorage.setItem("categories", JSON.stringify(categories));
      return categories;
    },
    deleteCategory: (state, { payload }) => {
      let categories = [...state].filter(
        (category) => payload._id !== category._id
      );
      localStorage.setItem("categories", JSON.stringify(categories));
      return categories;
    },
  },
});

export const { setCategories, addCategory, updateCategory, deleteCategory } =
  categorySlice.actions;

export default categorySlice.reducer;
