import { ChangeEvent, RefObject } from "react";
let devApi = "";
let prodApi = "https://office-server-481p.onrender.com";
export const api = prodApi;
export const handleMargin = (
  input: string,
  retailRef: RefObject<HTMLInputElement>,
  costRef: RefObject<HTMLInputElement>,
  setMargin: React.Dispatch<
    React.SetStateAction<{
      percent: number;
      profit: number;
    }>
  >,
  oldCost?: number,
  setOldMargin?: React.Dispatch<
    React.SetStateAction<{
      percent: number;
      profit: number;
    }>
  >
) => {
  return (e: ChangeEvent) => {
    if (oldCost && setOldMargin) {
      if (costRef.current?.value?.length!! > 0) {
        let newCost = parseInt(costRef.current?.value!!);
        let percent = Math.round(((newCost - oldCost) * 100) / oldCost);
        let profit = newCost - oldCost;
        setOldMargin({ profit, percent });
      }
    }
    if (input === "cost") {
      let rawCostValue = (e.target as HTMLInputElement).value.trim();

      if (retailRef?.current) {
        let rawRetailValue = retailRef.current.value.trim();
        if (rawRetailValue.length < 1 || rawCostValue.length < 1) return;
        let retailValue = parseInt(rawRetailValue);
        let costValue = parseInt(rawCostValue);
        let percent = Math.round(((retailValue - costValue) * 100) / costValue);
        let profit = retailValue - costValue;
        setMargin({ percent, profit });
      }
    } else if (input === "retail") {
      let rawRetailValue = (e.target as HTMLInputElement).value.trim();

      if (costRef?.current) {
        let rawCostValue = costRef.current.value.trim();
        if (rawRetailValue.length < 1 || rawCostValue.length < 1) return;
        let retailValue = parseInt(rawRetailValue);
        let costValue = parseInt(rawCostValue);
        let percent = Math.round(((retailValue - costValue) * 100) / costValue);
        let profit = retailValue - costValue;
        setMargin({ percent, profit });
      }
    }
  };
};
