import React, { FormEvent, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setEntity } from "../../controllers/entityReducer";
import { api } from "../../utilities/core";
import "./auth.scss";
interface LocationState {
  path: string;
}
const Auth = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useLocation().state as LocationState;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let path = state ? state.path : "/dashboard";
    e.currentTarget.setAttribute("disabled", "true");
    let form = new FormData(e.target as HTMLFormElement);

    setLoading(true);
    try {
      let request = await fetch(api + "/login-entity", {
        method: "POST",
        body: form,
      });
      if (request.ok) {
        let response = await request.json();

        dispatch(setEntity(response));
        navigate(path);
      }
      if (request.status === 401) {
        setError(true);
      }
      if (buttonRef.current) buttonRef.current.disabled = false;
    } catch (error) {
      console.log("failed", error);
      setError(true);
    }
    setLoading(false);
  };
  const handleFocus = () => {
    setError(false);
  };
  return (
    <div className="auth-container">
      <div className="login-con">
        <h3>mSoln</h3>
        <form className="auth-form" onSubmit={handleLogin}>
          {error && <small>Invalid username/password combination</small>}
          <div className="field">
            <label htmlFor="">Username</label>
            <input
              id="username"
              onFocus={handleFocus}
              name="username"
              type="text"
              placeholder="Zenith Inc"
              required
            />
          </div>
          <div className="field">
            <label htmlFor="">Password</label>
            <input
              id="password"
              placeholder="******"
              type="password"
              onFocus={handleFocus}
              name="password"
              required
            />
          </div>
          <button
            disabled={loading}
            ref={buttonRef}
            name="button"
            type="submit"
          >
            {loading ? "Signing in..." : "LOGIN"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Auth;
