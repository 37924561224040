import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ProductDoc } from "../../../../../types";
import { SortMode } from "../Products";
const Table = ({
  data,
  currentPage,
  PageSize,
  onSort,
  summary,
}: {
  data: ProductDoc[];
  currentPage: number;
  PageSize: number;
  onSort: (type: string) => () => void;
  summary: {
    quantityTotal: number;
    retailTotal: number;
    costTotal: number;
    totalProfit: number;
  };
}) => {
  const navigate = useNavigate();
  const handleClick = (id: string) => {
    return () => navigate("/product/" + id);
  };
  let sixMonths = moment().add("6", "months").toISOString();
  let oneYear = moment().add(1, "year").toISOString();
  return (
    <table>
      <thead>
        <tr>
          <th>#</th>
          <th onClick={onSort(SortMode.name)} title="Name of product">
            Name
          </th>
          <th onClick={onSort(SortMode.cost_price)} title="Cost Price">
            Cost Price
          </th>
          <th onClick={onSort(SortMode.sale_price)} title="Sale Price">
            Retail Rate
          </th>
          <th onClick={onSort(SortMode.quantity)} title="Quantity">
            Qty
          </th>
          <th onClick={onSort(SortMode.expiry_date)} title="Expiration Date">
            Exp date
          </th>
          <th onClick={onSort(SortMode.isBalanced)} title="Is">
            isBalanced
          </th>
          <th onClick={onSort(SortMode.createdAt)} title="Expiration Date">
            Added on
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((product, index) => {
          return (
            <tr
              className={
                " " +
                (product?.reorderLimit >= product?.quantity ? " os " : " ") +
                (product.expiry_date <= moment().toISOString()
                  ? " expired "
                  : product.expiry_date <= sixMonths &&
                    product.expiry_date > moment().toISOString()
                  ? " endangered "
                  : oneYear >= product.expiry_date &&
                    product.expiry_date > sixMonths
                  ? " caution "
                  : "")
              }
              onClick={handleClick(product._id)}
              key={product._id}
            >
              <td>{index + (currentPage - 1) * PageSize + 1}</td>
              <td>{product.name}</td>
              <td>&#8358;{product.cost_price?.toLocaleString()}</td>
              <td>&#8358;{product.sell_price?.toLocaleString()}</td>
              <td>{product.quantity?.toLocaleString()}</td>
              <td>{moment(product.expiry_date).format("MMM/YY")}</td>
              <td>{product.isBalanced?.toLocaleString()}</td>
              <td>{moment(product.createdAt).format("HH:mm:ss MMM/YY")}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
