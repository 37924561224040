import React, { FormEvent, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../..";
import { setEntity } from "../../../../controllers/entityReducer";
import { api } from "../../../../utilities/core";
import "./profile.scss";
function Profile() {
  const { entity } = useSelector((state: RootState) => state);
  const [isMatch, setIsMatch] = useState(false);
  const [password, setPassword] = useState("");
  const [showPasswordBox, setShowPasswordBox] = useState(false);
  const [showUsernameBox, setShowUsernameBox] = useState(false);
  const [showStaffForm, setShowStaffForm] = useState(false);
  const handleSubmit = async (e: FormEvent) => {
    try {
      e.preventDefault();
      let body = new FormData(e.target as HTMLFormElement);
      let req = await fetch(api + "/entity-password", {
        method: "PATCH",
        body,
        headers: { Authorization: "Bearer " + entity?.token },
      });
      if (req.ok) {
        alert("Password change successful");
        setEntity(await req.json());
      }
      if (req.status == 401) {
        alert("Invalid Password");
      }
    } catch (error) {
      alert("Failed");
    }
  };
  const handleSubmitEmail = async (e: FormEvent) => {
    try {
      e.preventDefault();
      let body = new FormData(e.target as HTMLFormElement);
      let req = await fetch(api + "/entity-email", {
        method: "PATCH",
        body,
        headers: { Authorization: "Bearer " + entity?.token },
      });
      if (req.ok) {
        alert("Email change successful");
        setEntity(await req.json());
      } else if (req.status == 401) alert("Invalid Password");
      else if (req.status == 403) alert("Email address unavailable");
    } catch (error) {
      alert("Failed");
    }
  };
  const handleAddStaff = async (e: FormEvent) => {
    try {
      e.preventDefault();
      let body = new FormData(e.target as HTMLFormElement);
      body.append("entityId", entity!!._id.toString());
      let req = await fetch(api + "/staff", {
        method: "POST",
        body,
        headers: { Authorization: "Bearer " + entity?.token },
      });
      if (req.ok) {
        alert("Staff Added successfully");
      } else if (req.status === 401) alert("Invalid Password");
    } catch (error) {
      alert("Failed");
    }
  };
  console.log("hell paul");
  return (
    <div className="profile-con">
      <div className="profile-con__profile-body">
        <h2>Account Details</h2>

        <Detail label="Username" title={entity?.username || "---"} />
        <Detail label="Email" title={entity?.email || "---"} />
        <div className="profile-con__profile-body__button-con">
          <button
            onClick={() => {
              setShowPasswordBox(false);
              setShowUsernameBox(!showUsernameBox);
            }}
          >
            Change Email
          </button>{" "}
          <button
            onClick={() => {
              setShowUsernameBox(false);
              setShowPasswordBox(!showPasswordBox);
            }}
          >
            Change Password
          </button>
          <button
            onClick={() => {
              setShowUsernameBox(false);
              setShowPasswordBox(false);
              setShowStaffForm(!showStaffForm);
            }}
          >
            Add Staff
          </button>
        </div>
      </div>
      {showPasswordBox && (
        <div className="profile-con__profile-body">
          <h2>Change Password</h2>
          <form
            onSubmit={handleSubmit}
            className="profile-con__profile-body__password-con"
          >
            <div className="profile-con__profile-body__password-con__input-con">
              <label htmlFor="">Old Password</label>
              <input
                type="password"
                name="oldPassword"
                id=""
                placeholder="******"
              />
            </div>
            <div className="profile-con__profile-body__password-con__input-con">
              <label htmlFor="">New Password</label>
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                value={password}
                name="newPassword"
                id=""
                placeholder="New password"
              />{" "}
            </div>

            <div className="profile-con__profile-body__password-con__input-con">
              <label htmlFor="">Confirm Password</label>
              <input
                onChange={(e) => {
                  if (e.target.value === password) setIsMatch(true);
                  else setIsMatch(false);
                }}
                type="password"
                name="confirm_password"
                id=""
                placeholder="Confirm Password"
              />
            </div>
            <small
              style={{
                opacity: !isMatch && password.trim().length > 0 ? 1 : 0,
              }}
            >
              Password does not match
            </small>

            <button disabled={!isMatch}>Save</button>
          </form>
        </div>
      )}
      {showUsernameBox && (
        <div className="profile-con__profile-body">
          <h2>Change Username</h2>
          <form
            onSubmit={handleSubmitEmail}
            className="profile-con__profile-body__password-con"
          >
            <div className="profile-con__profile-body__password-con__input-con">
              <label htmlFor="">Password</label>
              <input
                type="password"
                name="password"
                id=""
                placeholder="******"
              />
            </div>
            <div className="profile-con__profile-body__password-con__input-con">
              <label htmlFor="">New Email</label>
              <input
                type="email"
                name="email"
                id=""
                placeholder={entity?.email}
              />
            </div>
            <button>Save</button>
          </form>
        </div>
      )}
      {showStaffForm && (
        <div className="profile-con__profile-body">
          <h2>Add Staff</h2>
          <form
            onSubmit={handleAddStaff}
            className="profile-con__profile-body__password-con"
          >
            <InputCon
              name="firstname"
              type="text"
              placeholder="first name of staff"
              label="First Name"
            />
            <InputCon
              name="lastname"
              type="text"
              placeholder="Last name of staff"
              label="Last Name"
            />
            <InputCon
              name="username"
              label="Username"
              type="text"
              placeholder=""
            />
            <InputCon
              name="phoneNumber"
              label="Phone Number"
              type="phone"
              placeholder="080..."
            />
            <InputCon
              name="password"
              label="Password"
              placeholder="******"
              type="password"
            />
            <button>Save</button>
          </form>
        </div>
      )}
    </div>
  );
}

const Detail = (props: { label: string; title: string }) => (
  <div className="profile-con__profile-body__detail">
    <div className="profile-con__profile-body__detail__label">
      {props.label}
    </div>
    <div className="profile-con__profile-body__detail__title">
      {props.title}
    </div>
  </div>
);

const InputCon = ({
  label,
  name,
  placeholder,
  type,
}: {
  label: string;
  name: string;
  placeholder: string;
  type: string;
}) => (
  <div className="profile-con__profile-body__password-con__input-con">
    <label htmlFor="">{label}</label>
    <input type={type} name={name} id="" placeholder={placeholder} />
  </div>
);
export default Profile;
