import { createSlice } from "@reduxjs/toolkit";
import { ProductDoc } from "../types";
export interface ISupplier {
  _id: string;
  name: string;
  email: string;
  phone: string;
  entityIn: string;
  supplies: {
    supplier: string;
    supplierId: string;
    price: number;
    product: ProductDoc;
    quantity: number;
    entityId: string;
    expiry_date: Date;
  }[];
}
const hasSyppliers = localStorage.getItem("suppliers");
let initialState: ISupplier[] = hasSyppliers ? JSON.parse(hasSyppliers) : [];
const SupplierSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {
    addSupplier: (state, { payload }) => {
      let suppliers: ISupplier[] = [...state, payload].sort((a, b) =>
        a.category > b.category ? 1 : a.name < b.name ? -1 : 0
      );
      localStorage.setItem("supplierss", JSON.stringify(suppliers));
      return suppliers;
    },
    updateSupplier: (state, { payload }) => {
      let suppliers: ISupplier[] = [...state];
      suppliers[payload.index] = payload.supplier;
      localStorage.setItem("suppliers", JSON.stringify(suppliers));
      return suppliers;
    },
    deleteSupplier: (state, { payload }) => {
      let suppliers: ISupplier[] = [...state].filter(
        (supplier) => payload._id !== supplier._id
      );
      localStorage.setItem("suppliers", JSON.stringify(suppliers));
      return suppliers;
    },
    setSuppliers: (state, { payload }) => {
      localStorage.setItem("suppliers", JSON.stringify(payload));
      return payload;
    },
  },
});

export const { addSupplier, deleteSupplier, setSuppliers, updateSupplier } =
  SupplierSlice.actions;
export default SupplierSlice.reducer;
