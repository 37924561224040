import React, { MouseEvent, useState } from "react";
import Loader from "../../../../../resources/loader.gif";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
interface IProps {
  data: { name: String; value: number }[];
  total: number;
  title: String;
}
function ChartCard({ data, total, title }: IProps) {
  const [mode, setMode] = useState(0);

  const handleModeChange = (e: MouseEvent) => {
    if (mode < 2) {
      setMode(mode + 1);
    } else setMode(0);
  };
  return (
    <div className="chart-card">
      {data.length < 1 ? (
        <div className="chart-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          {" "}
          <div className="header">
            <p>{title}</p>
            <button onClick={handleModeChange}>Change Chart</button>
          </div>
          <ResponsiveContainer width="100%" height="85%">
            {mode === 0 ? (
              <PieChart>
                <Pie
                  dataKey="value"
                  isAnimationActive={true}
                  data={data}
                  cx="50%"
                  cy="50%"
                  innerRadius={70}
                  outerRadius={90}
                  fill="#006778"
                  label
                />
                <Pie
                  dataKey="count"
                  isAnimationActive={true}
                  data={data}
                  cx="50%"
                  cy="50%"
                  outerRadius={60}
                  fill="#0093ab"
                />

                <Tooltip />
              </PieChart>
            ) : mode === 1 ? (
              <LineChart
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="value" stroke="#006778" />
                <Line type="monotone" dataKey="count" stroke="#0093ab" />
              </LineChart>
            ) : (
              <BarChart
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Bar dataKey="value" fill="#006778" />
                <Bar dataKey="count" fill="#0093ab" />
              </BarChart>
            )}
          </ResponsiveContainer>
          <p>Total: &#8358;{total?.toLocaleString()}</p>
        </>
      )}
    </div>
  );
}

export default ChartCard;
