import React, { FormEvent, useRef, FocusEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setEntity } from "../../controllers/entityReducer";
import { api } from "../../utilities/core";
import "./auth.scss";

const Signup = () => {
  const cPasswordRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const passwordRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (e.target.value.trim() !== passwordRef.current?.value) {
      setError(true);
    } else {
      setError(false);
      setDisabled(false);
    }
  };
  const handleFocus = (e: FocusEvent) => {
    setError(false);
  };
  const handleSubmit = async (e: FormEvent) => {
    setDisabled(true);
    try {
      e.preventDefault();
      let form = new FormData(e.target as HTMLFormElement);
      let req = await fetch(api + "/entity", { method: "POST", body: form });
      if (req.ok) {
        let res = await req.json();
        dispatch(setEntity(res));
        navigate("/dashboard");
      }
    } catch (error) {
      console.log(error);
    }
    setDisabled(false);
  };
  return (
    <div className="auth-container">
      <div className="login-con">
        <h3>Add new Entity</h3>
        <form onSubmit={handleSubmit} className="auth-form">
          <div className="field">
            <label htmlFor="username">Username</label>
            <input required id="username" name="username" type="text" />
          </div>
          <div className="field">
            <label htmlFor="buisness name">Buisness Name</label>
            <input
              required
              name="buisnessName"
              id="buisness name"
              type="text"
            />
          </div>
          <div className="field">
            <label htmlFor="address">Address</label>
            <input required type="text" id="address" name="address" />
          </div>
          <div className="field">
            <label htmlFor="email">Email Address</label>
            <input required type="text" id="email" name="email" />
          </div>
          <div className="field">
            <label htmlFor="phone">Phone Number</label>
            <input required inputMode="tel" name="phone" id="phone" />
          </div>
          <div className="field">
            <label htmlFor="password">Password</label>
            <input
              required
              onFocus={handleFocus}
              ref={passwordRef}
              type="password"
              name="password"
            />
          </div>
          <div className="field">
            <label htmlFor="confirm password">Confirm Password</label>
            <input
              required
              type="password"
              ref={cPasswordRef}
              name="confirm password"
              onBlur={handleBlur}
              onFocus={handleFocus}
            />
            <small style={{ opacity: error ? "1" : 0 }}>
              Password does not match
            </small>
          </div>
          <button disabled={disabled}>
            {disabled ? "Please wait..." : "Save Entity"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Signup;
