import React, { ReactComponentElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, useLocation, useNavigate } from "react-router-dom";
import { RootState } from "..";

interface IProps {
  element: JSX.Element;
}
function ProtectedRoute(props: IProps) {
  const Element = props.element;
  const location = useLocation();
  const navigate = useNavigate();
  const { entity } = useSelector((state: RootState) => state);

  return !entity ? (
    <Navigate state={{ path: location.pathname }} to={"/"} replace />
  ) : (
    Element
  );
}

export default ProtectedRoute;
