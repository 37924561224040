import React, { FormEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../..";
import {
  addCategory,
  ICategory,
  setCategories,
  updateCategory,
} from "../../../../controllers/categoryReducer";
import { api } from "../../../../utilities/core";
import "./categories.scss";
function Categories() {
  let [color, setColor] = useState<string>("");
  let { entity } = useSelector((state: RootState) => state);
  let [categories, setCategories] = useState<ICategory[]>([]);
  let [showDialog, setShowDialog] = useState(false);
  let [selectedCategory, setSelectedCategory] = useState<ICategory>();
  let [isEdit, setIsEdit] = useState(false);
  const getCategories = async () => {
    try {
      let req = await fetch(api + "/categories", {
        headers: { Authorization: "Bearer " + entity?.token },
      });
      if (req.ok) {
        let categories = await req.json();
        setCategories(categories);
      }
      if (req.status === 401) {
        //   navigate("/");
      }
    } catch (error) {}
  };
  const dispatch = useDispatch();
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      let form = e.target as HTMLFormElement;
      let formBody = new FormData(form);
      let request = await fetch(api + "/category", {
        method: "POST",
        body: formBody,
        headers: { Authorization: "Bearer " + entity?.token },
      });
      if (request.ok) {
        let res = await request.json();
        dispatch(addCategory(res));
        setCategories((state) => {
          return [...state, res];
        });
        toast.success(res.category + " category successfully added", {
          duration: 2000,
        });
        form.reset();
      } else if (request.status === 403) {
        toast.error("Category exists");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const handleEdit = async (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      let form = e.target as HTMLFormElement;
      let formBody = new FormData(form);

      let request = await fetch(api + "/category/" + selectedCategory?._id, {
        method: "PATCH",
        body: formBody,
        headers: { Authorization: "Bearer " + entity?.token },
      });
      if (request.ok) {
        let index = categories.indexOf(selectedCategory!);
        console.log(index);

        let res = await request.json();

        setCategories((state) => {
          state[index] = res;
          state[index].products = res.products;
          return state;
        });
        //   dispatch(updateCategory({ category: res, index }));
        setSelectedCategory(undefined);
        setShowDialog(false);
        toast.success("Update successful");
        form.reset();
      } else if (request.status === 403) {
        toast.error("Category exists");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div
      className="category-con"
      onClick={(e) => {
        if (
          !(e.target as HTMLDivElement).closest(
            ".category-con__body__form-dialog"
          )
        ) {
          setSelectedCategory(undefined);
          setShowDialog(false);
          setColor("");
        }
      }}
    >
      <div className="category-con__body">
        {showDialog && (
          <CategoryDialog
            color={color}
            handleSubmit={handleSubmit}
            setColor={setColor}
            setShowDialog={setShowDialog}
            handleEdit={handleEdit}
            isEdit={isEdit}
            selectedCategory={selectedCategory}
            title={
              selectedCategory
                ? "Edit " + selectedCategory.category
                : "Add new category"
            }
          />
        )}

        <div className="category-con__body__header-con">
          <h3 className="category-con__body__header-con__header">Categories</h3>{" "}
          <p
            onClick={(e) => {
              e.stopPropagation();
              setShowDialog(true);
            }}
          >
            +
          </p>
        </div>

        <div className="category-con__body__main">
          <form className="category-con__body__main__search-bar">
            <input type="search" />
            <button>Search</button>
          </form>
          {categories.length > 0 ? (
            <div className="table-con">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Color</th>
                    <th>Show</th>
                    <th>Products</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((category, index) => (
                    <tr
                      onClick={(e) => {
                        e.stopPropagation();
                        if (selectedCategory) {
                          setShowDialog(false);
                          setSelectedCategory(undefined);
                          setColor("");
                          return;
                        }
                        setIsEdit(true);
                        setSelectedCategory(category);
                        setColor(category.color);
                        setShowDialog(true);
                      }}
                      key={category._id}
                    >
                      <td>{index + 1}</td>
                      <td>{category?.category}</td>
                      <td>{category?.color}</td>
                      <td>{category?.showOnTill}</td>
                      <td>{category?.products?.length}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div>
              <h3>
                No Category, To add a new category, please click on the "+" Sign
              </h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Categories;
interface IProps {
  handleSubmit: (e: FormEvent) => Promise<void>;
  handleEdit: (e: FormEvent) => Promise<void>;
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setColor: React.Dispatch<React.SetStateAction<string>>;
  color: string;
  title: string;
  isEdit: boolean;
  selectedCategory?: ICategory;
}

function CategoryDialog(props: IProps) {
  let colorOptions = [
    "#FFFFFF",
    "#C5E1A5",
    "#80DEEA",
    "#80CBC4",
    "#00BCD4",
    "#2196F3",
    "#3F51B5",
    "#9C27B0",
    "#E91E63",
    "#009688",
    "#FFEB3B",
    "#FF9800",
    "#B71C1C",
    "#880E4F",
    "#4A148C",
    "#311B92",
    "#004D40",
    "#827717",
    "#F57F17",
    "#006064",
  ];
  return (
    <form
      onSubmit={props.isEdit ? props.handleEdit : props.handleSubmit}
      className="category-con__body__form-dialog"
    >
      <p onClick={() => props.setShowDialog(false)} className="close">
        X
      </p>
      <h3>{props.title}</h3>
      <div className="category-con__body__form-dialog__main">
        <div className="category-con__body__form-dialog__main__input-con">
          <label htmlFor="name">Name</label>
          <input
            defaultValue={
              props.selectedCategory ? props.selectedCategory.category : ""
            }
            type="text"
            name="category"
            id="name"
          />
        </div>
        <div className="category-con__body__form-dialog__main__input-con">
          <label htmlFor="color">Color</label>
          <div className="inner-box">
            <select
              onChange={(e) => props.setColor(e.target.value)}
              name="color"
              id="color"
            >
              <option disabled value="">
                Select Color
              </option>
              {colorOptions.map((color) => (
                <option
                  key={color}
                  selected={color === props.color}
                  value={color}
                >
                  {color}
                </option>
              ))}
            </select>{" "}
            <div
              style={{ backgroundColor: props.color }}
              className="color-box"
            ></div>
          </div>
        </div>
        <div className="category-con__body__form-dialog__main__input-con">
          <label htmlFor="showOnTill">Show on Til</label>

          <select name="showOnTill" id="">
            <option
              selected={props.selectedCategory?.showOnTill === "YES"}
              value="Yes"
            >
              Yes
            </option>
            <option
              selected={props.selectedCategory?.showOnTill === "NO"}
              value="No"
            >
              No
            </option>
          </select>
        </div>
        <button>Save</button>
      </div>
    </form>
  );
}
