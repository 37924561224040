import { createSlice } from "@reduxjs/toolkit";
interface IEntity {
  token: string;
  _id: string;
  username: string;
  buisnessName: string;
  email: string;
  phone: string;
  message: string;
  password: string;
  formulations: [];
  categories: [];
  createdAt: string;
  updatedAt: string;
  printSilently: boolean;
  syncTime: number;
  address: string;
}
let hasEntity = localStorage.getItem("entity");
let initialState: IEntity | null = hasEntity ? JSON.parse(hasEntity) : null;
const entitySlice = createSlice({
  name: "Entity",
  initialState,
  reducers: {
    setEntity: (state, { payload }) => {
      localStorage.setItem("entity", JSON.stringify(payload));
      return payload;
    },
    logoutEntity: (state, { payload }) => {
      localStorage.clear();
      return null;
    },
  },
});

export const { setEntity, logoutEntity } = entitySlice.actions;
export default entitySlice.reducer;
