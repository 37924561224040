import moment from "moment";
import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ProductDoc } from "../../../../../types";
import { api, handleMargin } from "../../../../../utilities/core";
import loaderIcon from "../../../../../resources/loader.gif";
import { useSelector } from "react-redux";
import { RootState } from "../../../../..";
import toast from "react-hot-toast";
import Loader from "../../../../../utilities/Loader";
let timer: NodeJS.Timeout;
const Update = () => {
  let defaultMargin = { percent: 0, profit: 0 };

  let { entity, suppliers } = useSelector((state: RootState) => state);
  const [selectedProduct, setSelectedProduct] = useState<ProductDoc>();
  const location = useLocation();
  const [oldMargin, setOldMargin] = useState(defaultMargin);
  const [newMargin, setNewMargin] = useState(defaultMargin);
  const [products, setProducts] = useState<ProductDoc[]>([]);
  const [optionLoading, setOptionLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const retailRef = useRef<HTMLInputElement>(null);
  const costRef = useRef<HTMLInputElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const handleClick = (product: ProductDoc) => {
    return () => {
      setProducts([]);
      setSelectedProduct(product);
    };
  };
  // get product options
  const handleChange = (e: ChangeEvent) => {
    setOptionLoading(true);
    clearTimeout(timer);
    let query = (e.target as HTMLInputElement).value.trim();
    if (query.length < 1) {
      setProducts([]);
      setOptionLoading(false);
      return;
    }

    timer = setTimeout(async () => {
      try {
        let req = await fetch(api + "/product-options/" + query, {
          headers: { Authorization: "Bearer " + entity?.token },
        });
        if (req.ok) {
          let res = await req.json();
          setProducts(res);
        }
        if (req.status === 401) {
          navigate("/", { state: { path: location.pathname } });
          setLoading(false);
          return;
        }
        setOptionLoading(false);
      } catch (error) {
        setOptionLoading(false);
        console.log(error);
      }
    }, 1000);
  };
  // set update to server
  const handleSubmit = async (e: FormEvent) => {
    let toastId = toast.loading("Please wait");
    e.preventDefault();
    setLoading(true);
    let rawForm = e.target as HTMLFormElement;
    let form = new FormData(rawForm);
    form.append("_id", selectedProduct?._id!!);
    try {
      let request = await fetch(api + "/product", {
        method: "PATCH",
        body: form,
        headers: { Authorization: "Bearer " + entity?.token },
      });
      if (request.ok) {
        rawForm.reset();
        setNewMargin(defaultMargin);
        setOldMargin(defaultMargin);
        toast.success(selectedProduct?.name + "'s update was successful ", {
          id: toastId,
        });
        setSelectedProduct(undefined);
        searchRef.current?.focus();
      }
      if (request.status === 401) {
        navigate("/", { state: { path: location.pathname } });
        setLoading(false);
        toast.error("Session expired, please login", { id: toastId });
        return;
      }
      setLoading(false);
    } catch (error) {
      toast.error("Supply update failed", { id: toastId });
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log(location.state);
    if (location.state) setSelectedProduct(location.state as ProductDoc);
  }, []);
  return (
    <div className="update-con">
      {loading && <Loader />}
      <div className="search-con">
        <input
          onChange={handleChange}
          type="search"
          name="search"
          placeholder="Search Products..."
          autoFocus={true}
          autoComplete="off"
          autoCorrect="off"
          ref={searchRef}
        />
        {(products.length > 0 || optionLoading) && (
          <div className="search-options">
            {optionLoading ? (
              <p className="checking">
                Checking for similar products, Please wait...
              </p>
            ) : (
              <>
                {" "}
                <p tabIndex={0} onClick={() => setProducts([])}>
                  Clear suggestions{" "}
                </p>
                {products.map((product) => (
                  <p
                    tabIndex={0}
                    onClick={handleClick(product)}
                    key={product._id}
                  >
                    {product.name}
                  </p>
                ))}
              </>
            )}
          </div>
        )}
      </div>
      <h1 className="header">Update Product with Supply</h1>
      <form onSubmit={handleSubmit}>
        <h3>{selectedProduct?.name}</h3>
        <div className="input-con">
          <label htmlFor="quantity">
            Expiry Date (
            {moment(selectedProduct?.expiry_date).format("YYYY-MM-DD")})
          </label>
          <input
            required
            type="date"
            step="any"
            name="expiry_date"
            id="quantity"
            defaultValue={moment(selectedProduct?.expiry_date).format(
              "YYYY-MM-DD"
            )}
          />
        </div>
        <div className="input-con">
          <label htmlFor="quantity">
            Quantity ({selectedProduct?.quantity})
          </label>
          <input
            required
            type="number"
            step="any"
            name="quantity"
            id="quantity"
          />
        </div>
        <div className="input-con">
          <label htmlFor="cost_price">
            Cost Price (&#8358;{selectedProduct?.cost_price?.toLocaleString()})
          </label>
          <div className="margin-con">
            <small>
              {" "}
              &#8358;{oldMargin.profit} | {oldMargin.percent}%
            </small>
            <input
              type="number"
              onChange={handleMargin(
                "cost",
                retailRef,
                costRef,
                setNewMargin,
                selectedProduct?.cost_price,
                setOldMargin
              )}
              step="any"
              defaultValue={selectedProduct?.cost_price}
              id="cost_price"
              name="cost_price"
              ref={costRef}
              required
            />
          </div>
        </div>

        <div className="input-con">
          <label htmlFor="sell_price">
            Retail Rate (&#8358;{selectedProduct?.sell_price?.toLocaleString()})
          </label>
          <div className="margin-con">
            <small>
              &#8358;{newMargin.profit} | {newMargin.percent}%
            </small>
            <input
              ref={retailRef}
              onChange={handleMargin(
                "retail",
                retailRef,
                costRef,
                setNewMargin
              )}
              type="number"
              step="any"
              name="sell_price"
              defaultValue={selectedProduct?.sell_price}
              // onChange={handleMargin("retail", retailRef, costRef, setMargin)}
              id="Retail Rate"
            />
          </div>
        </div>
        <div className="input-con">
          <label htmlFor="supplier">Supplier</label>
          <select name="supplier" id="supplier">
            <option value="" disabled>
              Select Supplier
            </option>
            {suppliers.map((supplier) => (
              <option key={supplier._id} value={supplier._id}>
                {supplier.name}
              </option>
            ))}
          </select>
        </div>
        <button disabled={loading}>{loading ? "Updating..." : "Update"}</button>
      </form>
    </div>
  );
};

export default Update;
