import React, { FormEvent, useEffect, useState } from "react";
import "./supplier.scss";
import { ReactComponent as PlusIcon } from "../../../../resources/plus.svg";
import { ReactComponent as CloseIcon } from "../../../../resources/close.svg";
import { RootState } from "../../../..";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  addSupplier,
  setSuppliers,
} from "../../../../controllers/suppliersReducer";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../utilities/core";

function Supplier() {
  let { suppliers, entity } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  let [showForm, setShowForm] = useState(false);
  const navigate = useNavigate();
  const getSuppliers = async () => {
    try {
      let req = await fetch(api + "/suppliers/0/1000/", {
        headers: { Authorization: "Bearer " + entity?.token },
      });
      if (req.ok) {
        let res = await req.json();
        dispatch(setSuppliers(res));
      }
      if (req.status === 401) {
        navigate("/");
      }
    } catch (error) {}
  };
  useEffect(() => {
    getSuppliers();
  }, []);
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    let form = e.target as HTMLFormElement;
    let elements = form.elements;
    let button = elements[3] as HTMLButtonElement;
    button.disabled = true;
    try {
      let body = new FormData(form);
      let req = await fetch("/supplier", {
        method: "POST",
        body,
        headers: { Authorization: "Bearer " + entity?.token },
      });
      if (req.ok) {
        let res = await req.json();
        res.supplies = [];
        dispatch(addSupplier(res));
        form.reset();
      }
      button.disabled = false;
    } catch (error) {
      alert("error");
      button.disabled = false;
    }
  };
  return (
    <div className="supplier-con">
      {showForm && (
        <form onSubmit={handleSubmit} className="supplier-con__add-form">
          <CloseIcon onClick={() => setShowForm(false)} />
          <p className="header">Add Supplier</p>
          <div className="supplier-con__add-form__body">
            <div className="supplier-con__add-form__body__input-con">
              <label htmlFor="name">Name</label>{" "}
              <input
                autoComplete="off"
                autoCorrect="off"
                autoFocus={true}
                type="text"
                name="name"
                id=""
              />
            </div>
            <div className="supplier-con__add-form__body__input-con">
              <label htmlFor="email">Email</label>{" "}
              <input
                autoComplete="off"
                autoCorrect="off"
                type="email"
                name="email"
                id=""
              />
            </div>
            <div className="supplier-con__add-form__body__input-con">
              <label htmlFor="phone">Phone</label>{" "}
              <input
                autoComplete="off"
                autoCorrect="off"
                type="phone"
                name="phone"
                id=""
              />
            </div>
            <button name="button">SAVE</button>
          </div>
        </form>
      )}

      <div className="supplier-con__header-con">
        <h1 className="header">Suppliers</h1>
        <PlusIcon onClick={() => setShowForm(true)} />
      </div>
      <div className="supplier-con__body">
        <div className="supplier-con__body__table">
          {" "}
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Supplies</th>
              </tr>
            </thead>
            <tbody>
              {suppliers.map((supplier, index) => (
                <tr key={supplier._id}>
                  <td>{index + 1}</td>
                  <td>{supplier.name}</td>
                  <td>{supplier.email ?? "-"}</td>
                  <td>{supplier.phone ?? "-"}</td>
                  <td>{supplier.supplies.length}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Supplier;
