import moment from "moment";
import React, { useEffect, useState } from "react";
import Calender from "react-calendar";
import { useSelector } from "react-redux";
import { RootState } from "../../../..";
import { api } from "../../../../utilities/core";
import ChartCard from "./components/ChartCard";
import "./dashboard.scss";
const Dashboard = () => {
  let defaultDate = {
    month: moment().format("MMM-YY"),
    year: moment().format("YYYY"),
  };
  const { entity } = useSelector((state: RootState) => state);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [dayData, setDayData] = useState({ pieData: [], total: 0 });
  const [osCount, setOsCount] = useState(0);
  const [expCount, setExpCount] = useState(0);
  const [edCount, setEdCount] = useState(0);
  const [cautionCount, setCautionCount] = useState(0);
  const [weekData, setWeekData] = useState({ pieData: [], total: 0 });
  const [yearData, setYearData] = useState({ pieData: [], total: 0 });
  const [monthData, setMonthData] = useState({ pieData: [], total: 0 });

  const getDayStat = async (date: Date) => {
    let dat = moment(date).endOf("day").toISOString();
    setDayData({ pieData: [], total: 0 });

    let req = await fetch(api + "/day-stat/" + dat, {
      headers: { Authorization: "Bearer " + entity?.token },
    });
    if (req.ok) {
      let data = await req.json();
      setDayData(data);
    }
  };
  const getWeekStat = async (date: Date) => {
    setWeekData({ pieData: [], total: 0 });
    let dat = moment(date).endOf("day").toISOString();
    let req = await fetch(api + "/week-stat/" + dat, {
      headers: { Authorization: "Bearer " + entity?.token },
    });
    if (req.ok) {
      let data = await req.json();
      setWeekData(data);
    }
  };
  const getYearStat = async (date: Date) => {
    setYearData({ pieData: [], total: 0 });
    let dat = moment(date).endOf("day").toISOString();
    let req = await fetch(api + "/year-stat/" + dat, {
      headers: { Authorization: "Bearer " + entity?.token },
    });
    if (req.ok) {
      let data = await req.json();
      setYearData(data);
    }
  };
  const getMonthStat = async (date: Date) => {
    setMonthData({ pieData: [], total: 0 });
    let dat = moment(date).endOf("day").toISOString();
    let req = await fetch(api + "/month-stat/" + dat, {
      headers: { Authorization: "Bearer " + entity?.token },
    });
    if (req.ok) {
      let data = await req.json();
      setMonthData(data);
    }
  };
  const getExpCount = async () => {
    setMonthData({ pieData: [], total: 0 });

    let req = await fetch(api + "/exp-count", {
      headers: { Authorization: "Bearer " + entity?.token },
    });
    if (req.ok) {
      let data = await req.json();
      setExpCount(data.count);
    }
  };
  const getEndangeredCount = async () => {
    setMonthData({ pieData: [], total: 0 });

    let req = await fetch(api + "/endangered-count", {
      headers: { Authorization: "Bearer " + entity?.token },
    });
    if (req.ok) {
      let data = await req.json();
      setEdCount(data.count);
    }
  };
  const getCautionCount = async () => {
    setMonthData({ pieData: [], total: 0 });

    let req = await fetch(api + "/caution-count", {
      headers: { Authorization: "Bearer " + entity?.token },
    });
    if (req.ok) {
      let data = await req.json();
      setCautionCount(data.count);
    }
  };
  const getOsCount = async () => {
    setMonthData({ pieData: [], total: 0 });

    let req = await fetch(api + "/os-count", {
      headers: { Authorization: "Bearer " + entity?.token },
    });
    if (req.ok) {
      let data = await req.json();
      setOsCount(data.count);
    }
  };
  useEffect(() => {
    console.log(moment().week(1).startOf("week"));

    getDayStat(currentDate);
    getWeekStat(currentDate);
    getMonthStat(currentDate);
    getYearStat(currentDate);
    getOsCount();
    getExpCount();
    getEndangeredCount();
    getCautionCount();
    handleEffect();
  }, []);
  const handleChange = (value: any, event: any) => {
    setCurrentDate(value);
    if (defaultDate.month !== moment(value).format("MMM-YY"))
      getMonthStat(value);
    if (defaultDate.year !== moment(value).format("YYYY"))
      getYearStat(currentDate);
    getDayStat(value);
    getWeekStat(value);
  };
  const handleEffect = () => {};
  return (
    <div className="dashboard-con">
      <Calender onChange={handleChange} value={currentDate} />
      <div className="charts-con">
        <ChartCard
          title={moment(currentDate).format("dddd")}
          data={dayData.pieData}
          total={dayData.total}
        />
        <ChartCard
          title="Week"
          data={weekData.pieData}
          total={weekData.total}
        />
        <ChartCard
          title={`This Month`}
          data={monthData.pieData}
          total={monthData.total}
        />
        <ChartCard
          title={moment(currentDate).format("YYYY")}
          data={yearData.pieData}
          total={yearData.total}
        />
        <div className="charts-con horizontal">
          <div className="chart-card tile os">
            <h1>{osCount}</h1>
            <p>Out Of Stock</p>
          </div>
          <div className="chart-card tile caution">
            <h1>{cautionCount}</h1>
            <p>Caution Products</p>
          </div>
          <div className="chart-card tile ed">
            <h1>{edCount}</h1>
            <p>Endangered Products</p>
          </div>
          <div className="chart-card tile exp">
            <h1>{expCount}</h1>
            <p>Expired Products</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
