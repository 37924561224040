import moment from "moment";
import React, { useState } from "react";
import Calendar from "react-calendar";
import { useSelector } from "react-redux";
import { RootState } from "../../../..";
import { api } from "../../../../utilities/core";
import Loader from "../../../../utilities/Loader";

function SaleReport() {
  const [loading, setLoading] = useState(false);
  const { entity } = useSelector((state: RootState) => state);
  let [date, setDate] = useState<{ startDate: string; endDate: string }>({
    startDate: moment().startOf("week").toISOString(),
    endDate: moment().endOf("day").toISOString(),
  });
  const [totalCount, setTotalCount] = useState(0);
  let PageSize = 100;
  let skip = 0;
  const [id, setId] = useState("");
  const [timeInterval, setTimeInterval] = useState("None");
  const [transactionData, setTransactionData] = useState<{
    transactions: {
      _id: string;
      quantity: number;
      itemId: string;
      name: string;
      discount: number;
      sell_price: number;
      profit: number;
      total: number;
      createdAt: string;
    }[];
    totalDiscount: number;
    totalProfit: number;
    totalQuantity: number;
    totalSubTotal: number;
    total: number;
  }>();

  const handleFetchTransactions = async (
    _startDate: string = date.startDate,
    _endDate: string = date.endDate,
    _interval: string = timeInterval,
    _limit: number = PageSize,
    _skip: number = skip
  ) => {
    try {
      setLoading(true);

      let req = await fetch(`${api}/sale-report`, {
        body: JSON.stringify({
          _startDate,
          _endDate,
          _limit,
          _skip,
          _interval,
        }),
        method: "POST",
        headers: {
          Authorization: "Bearer " + entity?.token,
          "Content-type": "application/json",
        },
      });
      if (req.ok) {
        let res = await req.json();
        setTransactionData(res);
        setTotalCount(res.count || 0);
        setLoading(false);
        return;
      }
      throw new Error(await req.text());
    } catch (error) {
      setLoading(false);
      alert(error);
    }
  };
  const handleStartChange = (value: any) => {
    console.log(value);
    let startDate = moment(value[0]).startOf("day").format();
    let endDate = moment(value[1]).endOf("day").format();
    setDate({ startDate, endDate });
    handleFetchTransactions(startDate, endDate);
  };

  return (
    <div className="product-report-con">
      {loading && <Loader />}

      <p className="product-report-con__title">Sale Report</p>
      <div className="product-report-con__body">
        <h2>
          {moment(date.startDate).format("dddd DD MMMM YYYY ")} -{" "}
          {moment(date.endDate).format("dddd DD MMMM YYYY")}
        </h2>
        <form className="product-report-con__body__search-bar-con"></form>
        <div className="product-report-con__body__main">
          <div className="product-report-con__body__main__calender-con">
            <div className="product-report-con__body__main__calender-con__calender">
              <Calendar selectRange={true} onChange={handleStartChange} />
            </div>
          </div>
          <div className="product-report-con__body__main__interval">
            <label htmlFor="">Set Interval</label>
            <select
              onChange={(e) => {
                setTimeInterval(e.target.value);
                if (id)
                  handleFetchTransactions(
                    date.startDate,
                    date.endDate,
                    e.target.value
                  );
              }}
            >
              <option value="">None</option>
              <option value="hourly">Hourly</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </select>
          </div>

          <div className="product-report-con__body__main__table">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Trx Qty</th>
                  <th>Refund Qty</th>
                  <th>Refund Total</th>
                  <th>Discount Total</th>
                  <th>Sub-Total</th>
                  <th>Sale Total</th>
                  <th>Profit</th>
                </tr>
              </thead>
              <tbody>
                <tr className="bottom">
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td>
                    {transactionData?.totalQuantity?.toLocaleString()}unit(s)
                  </td>
                  <td></td>
                  <td>
                    &#8358;{transactionData?.totalDiscount?.toLocaleString()}
                  </td>
                  <td>
                    &#8358;{transactionData?.totalSubTotal?.toLocaleString()}
                  </td>
                  <td>&#8358;{transactionData?.total?.toLocaleString()}</td>
                  <td>
                    &#8358;{transactionData?.totalProfit?.toLocaleString()}
                  </td>
                </tr>
                {transactionData?.transactions?.map(
                  (
                    {
                      createdAt,
                      name,
                      quantity,
                      discount,
                      total,
                      profit,
                      sell_price,
                    },
                    index
                  ) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        {moment
                          .utc(createdAt)
                          .format(
                            timeInterval === "hourly"
                              ? "DD/MM/YY hh:mm"
                              : timeInterval === "weekly"
                              ? "DD/MM/YY"
                              : timeInterval === "daily"
                              ? "DD/MM/YY"
                              : timeInterval === "monthly"
                              ? "MMM/YYYY"
                              : timeInterval === "yearly"
                              ? "YYYY"
                              : "DD/MM/YY hh:mm:ss"
                          )}
                      </td>
                      <td>{name}</td>
                      <td>{quantity}</td>
                      <td>{sell_price}</td>
                      <td>{discount}</td>
                      <td>{total + discount}</td>
                      <td>{total}</td>
                      <td>{profit * quantity - discount}</td>
                    </tr>
                  )
                )}
                <tr className="bottom">
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td>
                    {transactionData?.totalQuantity?.toLocaleString()}unit(s)
                  </td>
                  <td></td>
                  <td>
                    &#8358;{transactionData?.totalDiscount?.toLocaleString()}
                  </td>
                  <td>
                    &#8358;{transactionData?.totalSubTotal?.toLocaleString()}
                  </td>
                  <td>&#8358;{transactionData?.total?.toLocaleString()}</td>
                  <td>
                    &#8358;{transactionData?.totalProfit?.toLocaleString()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={PageSize}
        onPageChange={(page) => handlePageChange(page)}
      /> */}
        </div>
      </div>
    </div>
  );
}

export default SaleReport;
