import React from "react";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Auth from "./view/auth/Auth";
import "./main.scss";
import Home from "./view/home/Home";
import "react-calendar/dist/Calendar.css";
import { createRoot } from "react-dom/client";
import Dashboard from "./view/home/components/dashboard/Dashboard";
import Products from "./view/home/components/products/Products";
import Product from "./view/home/components/products/components/Product";
import SignUp from "./view/auth/Signup";
import Import from "./view/home/components/supply/components/import";
import NewSupply from "./view/home/components/supply/components/NewSupply";
import Update from "./view/home/components/supply/components/Update";
import ProductUpdate from "./view/home/components/Update/Update";
import { Provider } from "react-redux";
import configureStore from "./controllers/configureStore";
import Transactions from "./view/home/components/report/Transactions";
import Expired from "./view/home/components/products/components/Expired";
import Endangered from "./view/home/components/products/components/Endangered";
import Os from "./view/home/components/products/components/Os";
import Caution from "./view/home/components/products/components/Caution";
import ProtectedRoute from "./utilities/ProtectedRoute";
import Profile from "./view/home/components/profile/Profile";
import Categories from "./view/home/components/products/Categories";
import Supplier from "./view/home/components/supplier/Supplier";
import ProductReport from "./view/home/components/report/ProductReport";
import SaleReport from "./view/home/components/report/SaleReport";
import Receipt from "./view/home/components/setup/Receipt";
import { Toaster } from "react-hot-toast";

const container = document.getElementById("root");

const root = createRoot(container!!);
let store = configureStore();
export type RootState = ReturnType<typeof store.getState>;
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Toaster />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Auth />} />
          <Route path="/xzx" element={<SignUp />} />
          <Route path="/productUpdate" element={<ProductUpdate />} />
          <Route element={<ProtectedRoute element={<Home />} />}>
            <Route
              path="/dashboard"
              element={<ProtectedRoute element={<Dashboard />} />}
            />
            <Route
              path="/product-list"
              element={<ProtectedRoute element={<Products />} />}
            />
            <Route path="/os" element={<ProtectedRoute element={<Os />} />} />
            <Route
              path="/endangered"
              element={
                <ProtectedRoute
                  element={<ProtectedRoute element={<Endangered />} />}
                />
              }
            />
            <Route
              path="/expired"
              element={<ProtectedRoute element={<Expired />} />}
            />
            <Route
              path="/suppliers"
              element={<ProtectedRoute element={<Supplier />} />}
            />
            <Route
              path="/caution"
              element={<ProtectedRoute element={<Caution />} />}
            />

            <Route
              path="/product/:id"
              element={<ProtectedRoute element={<Product />} />}
            />
            <Route
              path="/import"
              element={<ProtectedRoute element={<Import />} />}
            />
            <Route
              path="/add-product"
              element={<ProtectedRoute element={<NewSupply />} />}
            />
            <Route
              path="/update-stock"
              element={<ProtectedRoute element={<Update />} />}
            />
            <Route
              path="/transactions"
              element={<ProtectedRoute element={<Transactions />} />}
            />
            <Route
              path="/product_report"
              element={<ProtectedRoute element={<ProductReport />} />}
            />
            <Route
              path="/sale_report"
              element={<ProtectedRoute element={<SaleReport />} />}
            />
            <Route
              path="/profile"
              element={<ProtectedRoute element={<Profile />} />}
            />
            <Route
              path="/categories"
              element={<ProtectedRoute element={<Categories />} />}
            />
            <Route
              path="/receipt_settings"
              element={<ProtectedRoute element={<Receipt />} />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
