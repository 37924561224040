import React, { FormEvent, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ProductDoc } from "../../../../../types";
import Loader from "../../../../../resources/loader.gif";
import moment from "moment";
import { api } from "../../../../../utilities/core";
import { useSelector } from "react-redux";
import { RootState } from "../../../../..";
const Product = () => {
  const params = useParams();

  const navigate = useNavigate();
  const [product, setProduct] = useState<ProductDoc>();
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const { entity, categories } = useSelector((state: RootState) => state);
  useEffect(() => {
    (async () => {
      const id = params.id;
      try {
        let req = await fetch(api + "/product/" + id, {
          headers: { Authorization: "Bearer " + entity?.token },
        });
        if (req.ok) {
          let response = await req.json();
          setProduct(response);
        }
        if (req.status === 401) {
          navigate("/", { state: { path: location.pathname } });
        }
      } catch (error) {
        navigate("/");
      }
      setLoading(false);
    })();
  }, [navigate, params.id, entity?.token]);

  const handleEditMode = () => {
    setEditMode((state) => (state ? false : true));
  };
  const handleSubmit = async (event: FormEvent) => {
    setLoading(true);
    try {
      event.preventDefault();
      let form = new FormData(event.target as HTMLFormElement);
      form.append("_id", product!!._id);
      let req = await fetch(api + "/edit", {
        method: "PATCH",
        body: form,
        headers: {
          Authorization: "Bearer " + entity?.token,
        },
      });
      if (req.ok) {
        let product = await req.json();
        setProduct(product);
      }
      if (req.status === 401) {
        navigate("/", { state: { path: location.pathname } });
        setLoading(false);
        return;
      }
      setEditMode(false);
    } catch (error) {
      alert("Update failed");
      setEditMode(false);
    }
    setLoading(false);
  };
  const handleDelete = async () => {
    try {
      let query = window.confirm(
        `Delete "${product?.name}"? This action is irreversible`
      );
      setLoading(true);
      if (query) {
        let request = await fetch(api + "/product/" + params.id, {
          method: "DELETE",
        });
        if (request.ok) {
          setLoading(false);
          navigate("/product-list");
          return;
        }
        if (request.status === 401) {
          navigate("/", { state: { path: location.pathname } });
          setLoading(false);
          return;
        }

        alert("Delete failed, please contact support");
        setLoading(false);
        return;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <form onSubmit={handleSubmit} className="product-container">
      {loading && (
        <div className="loader-con">
          <img src={Loader} alt="Loading" />
        </div>
      )}

      {product && (
        <>
          {editMode ? (
            <input
              autoFocus
              className="name"
              defaultValue={product?.name}
              type="text"
              name="name"
              required
              id=""
            />
          ) : (
            <h1>{product?.name}</h1>
          )}
          <div className="product-body">
            <div className="product-details">
              <div className="product-detail">
                <p>Category</p>
                {editMode ? (
                  <select name="category">
                    {categories.map((category) => (
                      <option
                        selected={category._id === product.category._id}
                        value={category._id}
                      >
                        {category.category}
                      </option>
                    ))}
                  </select>
                ) : (
                  <p>{product?.category.category}</p>
                )}
              </div>
              <div className="product-detail">
                <p>Barcode</p>
                {editMode ? (
                  <input
                    defaultValue={product?.barcode}
                    name="barcode"
                    type="text"
                  />
                ) : (
                  <p>{product?.barcode}</p>
                )}
              </div>
              <div className="product-detail">
                <p>Quantity</p>
                {editMode ? (
                  <input
                    defaultValue={product?.quantity}
                    name="quantity"
                    required
                    type="number"
                  />
                ) : (
                  <p>{product?.quantity?.toLocaleString()} unit(s)</p>
                )}
              </div>
              <div className="product-detail">
                <p>isBalanced</p>
                {editMode ? (
                  <input
                    defaultChecked={product.isBalanced}
                    name="isBalanced"
                    type="checkbox"
                    value={"true"}
                  />
                ) : (
                  <p>{product?.isBalanced?.toLocaleString()}</p>
                )}
              </div>
              <div className="product-detail">
                <p>Reorder limit</p>
                {editMode ? (
                  <input
                    defaultValue={product?.reorderLimit}
                    name="reorderLimit"
                    required
                    type="number"
                  />
                ) : (
                  <p>{product?.reorderLimit?.toLocaleString()}</p>
                )}
              </div>
              <div className="product-detail">
                <p>Cost Price</p>
                {editMode ? (
                  <input
                    defaultValue={product?.cost_price}
                    step="any"
                    name="cost_price"
                    required
                    type="number"
                  />
                ) : (
                  <p>&#8358;{product?.cost_price?.toLocaleString()}</p>
                )}
              </div>
              <div className="product-detail">
                <p>Retail Rate</p>
                {editMode ? (
                  <input
                    defaultValue={product?.sell_price}
                    step="any"
                    name="sell_price"
                    required
                    type="number"
                  />
                ) : (
                  <p>&#8358;{product?.sell_price?.toLocaleString()}</p>
                )}
              </div>
              <div className="product-detail">
                <p>Profit Margin</p>
                <p>
                  {(
                    ((product?.sell_price - product?.cost_price) * 100) /
                    product?.cost_price
                  )?.toLocaleString()}
                  %
                </p>
              </div>
              <div className="product-detail">
                <p>Profit per sale</p>
                <p>&#8358;{product?.sell_price - product?.cost_price}</p>
              </div>
              <div className="product-detail">
                <p>Total profit made</p>
                <p>&#8358;{product?.total_profit?.toLocaleString()}</p>
              </div>
              <div className="product-detail">
                <p>Quantity Sold</p>
                <p>{product?.quantity_sold} unit(s)</p>
              </div>
              <div className="product-detail">
                <p>Expiration date</p>
                {editMode ? (
                  <input
                    type="date"
                    defaultValue={moment(product?.expiry_date).format(
                      "YYYY-MM-DD"
                    )}
                    name="expiry_date"
                    required
                  />
                ) : (
                  <p>{moment(product?.expiry_date).format("MMMM-YYYY")}</p>
                )}
              </div>
              <div className="product-detail">
                <p>Added on</p>
                <p>{moment(product?.createdAt).format("Do-MMMM-YYYY")}</p>
              </div>
              {editMode ? (
                <div className="button-con">
                  <button>Save</button>
                  <div className="button negative" onClick={handleEditMode}>
                    Cancel
                  </div>
                </div>
              ) : (
                <div className="button-con">
                  <div className="button" onClick={handleEditMode}>
                    EDIT
                  </div>
                  <div onClick={handleDelete} className="button negative">
                    Delete
                  </div>
                </div>
              )}
            </div>
            <div className="product-stat">
              <h1>COMING SOON</h1>
            </div>
          </div>
        </>
      )}
      <div className="product-container__main">
        <div className="product-container__main__price-history"></div>
      </div>
    </form>
  );
};

export default Product;
