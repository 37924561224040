import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./home.scss";
import { ReactComponent as DashboardIcon } from "../../resources/dboard2.svg";
import { ReactComponent as ProfileIcon } from "../../resources/profile.svg";
import { ReactComponent as SetupIcon } from "../../resources/setup.svg";
import { ReactComponent as ProductsIcon } from "../../resources/products2.svg";
import { ReactComponent as ReportIcon } from "../../resources/report.svg";
import { ReactComponent as SuppliersIcon } from "../../resources/suppliers.svg";
import { ReactComponent as SupplyIcon } from "../../resources/supply.svg";
import { ReactComponent as ArrowIcon } from "../../resources/arrow.svg";
import { ReactComponent as LogoutIcon } from "../../resources/logout.svg";
import { useSelector } from "react-redux";
import { api } from "../../utilities/core";
import { RootState } from "../..";
import { useDispatch } from "react-redux";
import { logoutEntity } from "../../controllers/entityReducer";
import { setCategories } from "../../controllers/categoryReducer";
import { setSuppliers } from "../../controllers/suppliersReducer";
function Home() {
  const navigate = useNavigate();
  const { entity } = useSelector((state: RootState) => state);
  const location = useLocation();
  const [options, setOptions] = useState<{ label: string; path: string }[]>([]);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const sub1 = useRef<HTMLDivElement>(null);
  const sub2 = useRef<HTMLDivElement>(null);
  const nav = useRef<HTMLDivElement>(null);

  const getCategories = async () => {
    try {
      let req = await fetch(api + "/categories", {
        headers: { Authorization: "Bearer " + entity?.token },
      });
      if (req.ok) {
        let categories = await req.json();
        dispatch(setCategories(categories));
      }
      if (req.status === 401) {
        navigate("/");
      }
    } catch (error) {}
  };
  const getSuppliers = async () => {
    try {
      let req = await fetch(api + "/suppliers/0/50/", {
        headers: { Authorization: "Bearer " + entity?.token },
      });
      if (req.ok) {
        let res = await req.json();
        dispatch(setSuppliers(res));
      }
      if (req.status === 401) {
        navigate("/");
      }
    } catch (error) {}
  };
  const optionsObject = {
    products: [
      { label: "Products", path: "/product-list" },
      { label: "Os", path: "/os" },
      { label: "Caution", path: "/caution" },
      { label: "Endagered", path: "/endangered" },
      { label: "Expired", path: "/expired" },
      { label: "Potential", path: "/wishlist" },
      { label: "Categories", path: "/categories" },
    ],

    supply: [
      {
        label: "Supplies",
        path: "/supply",
      },
      {
        label: "Import Products",
        path: "/import",
      },
      { label: "Add Product", path: "/add-product" },
      { label: "Update Stock", path: "/update-stock" },
    ],
    report: [
      { label: "Transactions", path: "/transactions" },
      { label: "Product Report", path: "/product_report" },
      { label: "Sale Report", path: "/sale_report" },
      { label: "Compare ", path: "/" },
      { label: "Recent Trx", path: "/" },
    ],
    setup: [
      { label: "Receipt", path: "/receipt_settings" },
      { label: "Payment Methods", path: "/receipt" },
    ],
  };

  const handleClick = (
    options: { label: string; path: string }[],
    title: string
  ) => {
    return () => {
      setOptions(options);
      setTitle(title);
      setTimeout(() => getWidth(sub2.current!!), 0.3);
    };
  };

  const getWidth = (ref: HTMLDivElement) => {
    if (sub1.current) {
      let dimen = {
        width: ref.clientWidth + 5 + 5,
        height: ref.clientHeight + 20,
      };
      setDimensions(dimen);
    } else return { width: 0, height: 0 };
  };

  useEffect(() => {
    getWidth(sub1.current!!);
    getCategories();
    getSuppliers();
  }, []);
  const handleShowMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setShowMenu((state) => (state ? false : true));
  };
  const handleCloseMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!(e.target as HTMLDivElement).closest(".menu")) {
      setShowMenu(false);
    }
  };
  const handleLogout = async () => {
    try {
      let req = await fetch(api + "/logout-entity", {
        headers: { Authorization: "Bearer " + entity?.token },
      });
      if (req.ok) {
        dispatch(logoutEntity(entity));
        navigate("/");
        return;
      }
      throw new Error("You're not looged in");
    } catch (error) {
      alert(error);
      navigate("/");
    }
  };
  return (
    <div onClick={handleCloseMenu} className="home-con">
      <div className="nav-bar">
        <div
          onClick={handleShowMenu}
          className={"hamburger " + (showMenu ? "active" : "")}
        >
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </div>
        <p className="nav-bar__logo">mSoln</p>
        <p>{entity?.buisnessName}</p>
      </div>
      <div className="home-content">
        <div
          ref={nav}
          style={{
            height: dimensions.height,
            left: showMenu ? "0" : "-" + nav.current?.clientWidth + "px",
          }}
          className="menu"
        >
          <div
            style={{
              transform: options.length > 0 ? "translateX(-50%)" : "",
            }}
            className="slide"
          >
            <div ref={sub1} className="sub-menu-one ">
              <div
                onClick={() => {
                  setShowMenu(false);
                  navigate("/profile");
                }}
                className={
                  "menu-item " +
                  (location.pathname === "/profile" ? "active" : "")
                }
              >
                <ProfileIcon />
                <p>Profile</p>
              </div>
              <div
                onClick={() => {
                  setShowMenu(false);
                  navigate("/dashboard");
                }}
                className={
                  "menu-item " +
                  (location.pathname === "/dashboard" ? "active" : "")
                }
              >
                <DashboardIcon />
                <p>Dashboard</p>
              </div>
              <div
                onClick={handleClick(optionsObject.products, "Products")}
                className={
                  "menu-item " +
                  (optionsObject.products.filter(
                    (product) => product.path === location.pathname
                  ).length > 0
                    ? "active"
                    : "")
                }
              >
                <ProductsIcon />
                <p>Products</p>
              </div>
              <div
                onClick={handleClick(optionsObject.report, "Report")}
                className={
                  "menu-item " +
                  (optionsObject.report.filter(
                    (product) => product.path === location.pathname
                  ).length > 0
                    ? "active"
                    : "")
                }
              >
                <ReportIcon />
                <p>Report</p>
              </div>
              <div
                onClick={handleClick(optionsObject.supply, "Supply")}
                className={
                  "menu-item " +
                  (optionsObject.supply.filter(
                    (product) => product.path === location.pathname
                  ).length > 0
                    ? "active"
                    : "")
                }
              >
                <SupplyIcon />
                <p>Supply</p>
              </div>
              <div
                onClick={() => {
                  setShowMenu(false);
                  navigate("/suppliers");
                }}
                className={
                  "menu-item " +
                  (location.pathname === "/suppliers" ? "active" : "")
                }
              >
                <SuppliersIcon />
                <p>Suppliers</p>
              </div>
              <div
                onClick={handleClick(optionsObject.setup, "Settings")}
                className={
                  "menu-item " +
                  (optionsObject.setup.filter(
                    (product) => product.path === location.pathname
                  ).length > 0
                    ? "active"
                    : "")
                }
              >
                <SetupIcon />
                <p>Setup</p>
              </div>
              <div
                onClick={handleLogout}
                className={
                  "menu-item " + (location.pathname === "" ? "active" : "")
                }
              >
                <LogoutIcon />
                <p>Logout</p>
              </div>
            </div>
            <div ref={sub2} className="sub-menu-options">
              <div className="title-con">
                <ArrowIcon
                  onClick={() => {
                    setOptions([]);
                    getWidth(sub1.current!!);
                  }}
                />{" "}
                <p
                  onClick={() => {
                    setOptions([]);
                    getWidth(sub1.current!!);
                  }}
                >
                  {title}
                </p>
              </div>
              {options.map((option) => (
                <p
                  onClick={() => {
                    setShowMenu(false);
                    navigate(option.path);
                  }}
                  className={
                    "option " +
                    (location.pathname === option.path ? "active" : "")
                  }
                >
                  {option.label}
                </p>
              ))}
            </div>
          </div>
        </div>{" "}
        <Outlet />
      </div>
    </div>
  );
}

export default Home;
