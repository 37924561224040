import Table from "./components/Table";
import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Loader from "../../../../utilities/Loader";
import "./products.scss";
import { ProductDoc } from "../../../../types";
import Pagination from "../../../../utilities/Pagination";
import { api } from "../../../../utilities/core";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../..";
export enum SortMode {
  name = "name",
  cost_price = "cost_price",
  sale_price = "sell_price",
  expiry_date = "expiry_date",
  quantity = "quantity",
  createdAt = "createdAt",
  isBalanced = "isBalanced",
}
export enum PageMode {
  expired = "expired",
  caution = "caution",
  endangered = "endangered",
  os = "os",
  productList = "product-list",
}
const Products = () => {
  let PageSize = 100;
  const navigate = useNavigate();

  const { entity, categories } = useSelector((state: RootState) => state);
  const [summary, setSummary] = useState({
    quantityTotal: 0,
    retailTotal: 0,
    costTotal: 0,
    totalProfit: 0,
  });
  const [pageMode, setPageMode] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  let [count, setCount] = useState(0);
  let location = useLocation();
  const [products, setProducts] = useState<{
    products: ProductDoc[];
    count: number;
  }>({ products: [], count: 0 });
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [category, setCategory] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [sort, setSort] = useState<{ type: string; mode: number }>({
    type: SortMode.name,
    mode: 1,
  });
  const currentTableData = useMemo(() => {
    return products.products; //slice(firstPageIndex, lastPageIndex);
  }, [products]);
  const getProducts = async (
    page = 0,
    query = "all",
    sort: { type: string; mode: number },
    mode = pageMode,
    _category = category
  ) => {
    try {
      setIsLoading(true);
      let req = await fetch(
        `${api}/products/${PageSize}/${page}/${
          query.length < 1 ? "all" : query
        }/${JSON.stringify(sort)}/${mode}/${_category}`,
        {
          headers: { Authorization: `Bearer ${entity?.token}` },
        }
      );
      if (req.ok) {
        let res = await req.json();
        setProducts(res);
        setCurrentPage(page + 1);
        setQuery(query);
      } // else setProducts({ products: [], count: 0 });
      if (req.status === 401) {
        navigate("/", { state: { path: location.pathname } });
      }
      setIsLoading(false);
    } catch (error) {
      alert("Could not load products");
      setIsLoading(false);
    }
  };
  const getExtra = async (pageMode: string) => {
    try {
      let req = await fetch(
        `${api}/products-info/${query.length < 1 ? "all" : query}/${pageMode}`,
        {
          headers: { Authorization: `Bearer ${entity?.token}` },
        }
      );
      if (req.ok) {
        let res = await req.json();
        setSummary(res);
      }
    } catch (error) {}
  };
  useEffect(() => {
    let pageMode = location.pathname.slice(1);

    setPageMode(pageMode);
    (async () => {
      try {
        if (entity?.token) {
          let req = await fetch(api + "/product-count/" + pageMode, {
            headers: { Authorization: `Bearer ${entity?.token}` },
          });
          if (req.ok) {
            let res = await req.json();
            setCount(res.count);
          }
        }
      } catch (error) {}
    })();
    getProducts(
      0,
      searchParams.get("query") ? searchParams.get("query")!! : "all",
      sort,
      pageMode
    );
    //  getExtra(pageMode);
  }, []);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let queryString = inputRef?.current?.value.trim();
    if (queryString) setSearchParams({ query: queryString });
    getProducts(0, queryString, sort);
  };
  const handlePageChange = (page: number) => {
    //    setCurrentPage(page);
    getProducts(page - 1, query, sort);
  };
  const handleReset = () => {
    getProducts(0, "all", sort);
  };
  const handleSort = (type: string) => {
    return () => {
      let mSort = { type: "", mode: -1 };
      if (sort.type === type && sort.mode === -1) mSort = { type, mode: 1 };
      else if (sort.type === type) mSort = { type, mode: -1 };
      else mSort = { type, mode: 1 };
      setSort(mSort);
      getProducts(0, query, mSort);
    };
  };

  const handleCategoryChange = async (e: ChangeEvent) => {
    let value = (e.target as HTMLSelectElement).value;
    try {
      setCategory(value);
      getProducts(0, query, sort, pageMode, value);
    } catch (error) {}
  };
  return (
    <div className="products-container">
      {isLoading && <Loader />}
      <h2>
        {pageMode} ({count})
      </h2>
      <div className="search-container">
        <form onSubmit={handleSubmit} className="search-box">
          <input
            type="search"
            autoComplete="yes"
            autoFocus={true}
            name="search"
            id=""
            placeholder="Name, Price, Barcode, Supplier"
            ref={inputRef}
          />
          <button type="submit">Search</button>
        </form>
        <select onChange={handleCategoryChange} name="category">
          <option value="">All</option>
          {categories.map((category) => (
            <option key={category._id} value={category._id}>
              {category.category} ({category.products.length})
            </option>
          ))}
        </select>
      </div>
      {query.length > 0 && query !== "all" && (
        <div className="search-details">
          <p title="Clear search" onClick={handleReset}>
            X
          </p>
          <h3>
            Showing result(s) for <span>{query}</span>
          </h3>
          <small>{products.count} product(s) found</small>
        </div>
      )}
      <div className="products-list">
        <Table
          data={currentTableData}
          PageSize={PageSize}
          currentPage={currentPage}
          onSort={handleSort}
          summary={{ ...summary }}
        />
      </div>{" "}
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={products.count ? products.count : 0}
        pageSize={PageSize}
        onPageChange={(page) => handlePageChange(page)}
      />
    </div>
  );
};

export default Products;
