import React, { FormEvent } from "react";
import { api } from "../../../../utilities/core";

function Update() {
  const handleSubmit = async (e: FormEvent) => {
    try {
      e.preventDefault();
      let body = new FormData(e.target as HTMLFormElement);
      let req = await fetch(api + "/product-update", {
        method: "POST",
        body: body,
        //  headers: { "Content-type": "multipart/form-data" },
      });
      if (req.ok) {
        alert("file sent");
      }
    } catch (error) {
      //@ts-ignore
      alert(error.message);
    }
  };
  return (
    <div>
      <form action="" onSubmit={handleSubmit}>
        <input type="text" name="version" id="" />
        <input type="file" name="file" id="" />
        <input type="submit" value="Submit Update" />
      </form>
    </div>
  );
}

export default Update;
