import React, {
  ChangeEvent,
  FormEvent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import { ProductDoc } from "../../../../../types";
import { api, handleMargin } from "../../../../../utilities/core";
import loaderIcon from "../../../../../resources/loader.gif";
import "../supply.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../..";
import { useDispatch } from "react-redux";
import { addSupplier } from "../../../../../controllers/suppliersReducer";
import Loader from "../../../../../utilities/Loader";
import { ICategory } from "../../../../../controllers/categoryReducer";
let timer: NodeJS.Timeout;
const NewSupply = () => {
  let { entity, suppliers } = useSelector((state: RootState) => state);
  let [categories, setCategories] = useState<ICategory[]>([]);
  const navigate = useNavigate();
  const [optionLoading, setOptionLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [margin, setMargin] = useState({ percent: 0, profit: 0 });
  const costRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const retailRef = useRef<HTMLInputElement>(null);
  const [products, setProducts] = useState<ProductDoc[]>([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const handleChange = (e: ChangeEvent) => {
    setOptionLoading(true);
    let query = (e.target as HTMLInputElement).value.trim();
    if (query.length < 1) {
      setProducts([]);
      setOptionLoading(false);
      return;
    }
    clearTimeout(timer);
    timer = setTimeout(async () => {
      try {
        let req = await fetch(api + "/product-options/" + query, {
          headers: { Authorization: "Bearer " + entity?.token },
        });
        if (req.ok) {
          let res = await req.json();

          setProducts(res);
        }
        if (req.status === 401) {
          navigate("/", { state: { path: location.pathname } });
          setLoading(false);
          return;
        }
        setOptionLoading(false);
      } catch (error) {
        setOptionLoading(false);
        console.log(error);
      }
    }, 1000);
  };
  const handleClick = (product: ProductDoc) => {
    return () => {
      setProducts([]);
      navigate("/update-stock/", { state: product });
    };
  };
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    let id = toast.loading("Please wait...");
    let target = e.target as HTMLFormElement;
    let form = new FormData(target);
    try {
      let req = await fetch(api + "/product", {
        method: "POST",
        body: form,
        headers: { Authorization: "Bearer " + entity?.token },
      });
      if (req.ok) {
        let res = await req.json();
        if (res.supplier) {
          let supplier = res.supplier;
          supplier.supplies = [];
          dispatch(addSupplier(supplier));
        }
        target.reset();
        setMargin({ percent: 0, profit: 0 });
        setLoading(false);
        toast.success("Product saved successfully", { id });
        return;
      }
      if (req.status === 401) {
        toast.error("Session expired, please login", { id });
        navigate("/", { state: { path: location.pathname } });
        setLoading(false);
        return;
      }
    } catch (error) {
      toast.error("Supply update failed", { id });
    }
    setLoading(false);
  };
  const getCategories = async () => {
    try {
      let req = await fetch(api + "/categories", {
        headers: { Authorization: "Bearer " + entity?.token },
      });
      if (req.ok) {
        let categories = await req.json();
        setCategories(categories);
      }
      if (req.status === 401) {
        navigate("/");
      }
    } catch (error) {}
  };
  useEffect(() => {
    getCategories();
  }, []);
  return (
    <div className="new-supply-con">
      {loading && <Loader />}

      <h1 className="header">Add New Product</h1>
      <form onSubmit={handleSubmit}>
        <div className="input-con">
          <label htmlFor="name">Name</label>
          <div className="inner-con">
            <input
              required
              ref={nameRef}
              autoComplete="off"
              autoCorrect="off"
              onChange={handleChange}
              type="text"
              name="name"
              id="name"
            />
            {(products.length > 0 || optionLoading) && (
              <div className="search-options">
                {optionLoading ? (
                  <p className="checking">
                    Checking for similar products, Please wait...
                  </p>
                ) : (
                  <>
                    {" "}
                    <p onClick={() => setProducts([])}>Clear suggestions </p>
                    {products.map((product) => (
                      <p onClick={handleClick(product)} key={product._id}>
                        {product.name}
                      </p>
                    ))}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="input-con">
          <label htmlFor="category">Category</label>

          <select required name="category">
            <option value="" disabled>
              Select category
            </option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.category}
              </option>
            ))}
          </select>
        </div>
        <div className="input-con">
          <label htmlFor="barcode">Barcode</label>
          <input type="text" name="barcode" id="barcode" />
        </div>
        <div className="input-con">
          <label htmlFor="quantity">Quantity</label>
          <input required type="number" step="any" name="quantity" id="name" />
        </div>
        <div className="input-con">
          <label htmlFor="reorder limit">Reorder limit</label>
          <input
            required
            type="number"
            name="reorderLimit"
            id="reorder limit"
          />
        </div>
        <div className="input-con">
          <label htmlFor="Expiration Date">Expiration Date</label>
          <input required type="date" name="expiry_date" id="Expiration Date" />
        </div>
        <div className="input-con">
          <label htmlFor="Supplier">Supplier</label>
          <select name="supplier" id="Supplier">
            <option value="" disabled>
              Select Supplier
            </option>
            {suppliers.map((supplier) => (
              <option value={supplier._id}>{supplier.name}</option>
            ))}
          </select>
        </div>
        <div className="input-con">
          <label htmlFor="Cost Price">Cost Price</label>
          <input
            required
            ref={costRef}
            type="number"
            step="any"
            name="cost_price"
            onChange={handleMargin("cost", retailRef, costRef, setMargin)}
            id="Cost Price"
          />
        </div>
        <div className="input-con">
          <label htmlFor="Retail Rate">Retail Rate</label>
          <div className="margin-con">
            <small>
              &#8358;{margin.profit} | {margin.percent}%
            </small>
            <input
              required
              ref={retailRef}
              type="number"
              step="any"
              name="sell_price"
              onChange={handleMargin("retail", retailRef, costRef, setMargin)}
              id="Retail Rate"
            />
          </div>
        </div>
        <button>Save Product</button>
      </form>
    </div>
  );
};

export default NewSupply;
