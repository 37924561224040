import React, { FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../..";
import { setEntity } from "../../../../controllers/entityReducer";
import "./setup.scss";
import { api } from "../../../../utilities/core";
function Receipt() {
  const { entity } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [isForm, setIsForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: FormEvent) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      let body = new FormData(e.target as HTMLFormElement);
      let req = await fetch(api + "/entity", {
        method: "PATCH",
        body,
        headers: { Authorization: "Bearer " + entity?.token },
      });
      if (req.ok) {
        dispatch(setEntity({ ...(await req.json()), token: entity?.token }));
      }
    } catch (error) {
      alert("Failed");
    }
    setIsLoading(false);
    setIsForm(false);
  };
  return (
    <div className="receipt-con">
      <h1 className="header">Receipt Settings</h1>
      <form onSubmit={handleSubmit} className="receipt-con__body">
        <Detail
          title="Company Name"
          value={entity?.buisnessName!}
          isForm={isForm}
          name="buisnessName"
        />
        <Detail
          title="Company Address"
          value={entity?.address!}
          isForm={isForm}
          name="address"
        />
        <Detail
          name="email"
          title="Company Email"
          value={entity?.email!}
          isForm={isForm}
        />
        <Detail
          name="phone"
          title="Company Phone"
          value={entity?.phone!}
          isForm={isForm}
        />
        <Detail
          title="Appreciation Message"
          value={entity?.message!}
          isForm={isForm}
          name="message"
        />
        <div className="receipt-con__body__detail">
          <p className="receipt-con__body__detail__label">Print Silently</p>
          {isForm ? (
            <select name="printSilently" id="">
              <option selected={entity?.printSilently === true} value="true">
                True
              </option>
              <option selected={entity?.printSilently === false} value="false">
                False
              </option>
            </select>
          ) : (
            <p className="receipt-con__body__detail__value">
              {entity?.printSilently.toString()}
            </p>
          )}
        </div>

        <div className="receipt-con__body__button-row">
          {!isForm && (
            <div className="button" onClick={() => setIsForm(true)}>
              Edit
            </div>
          )}
          {isForm && (
            <>
              {" "}
              <button disabled={isLoading}>
                {isLoading ? "Please Wait..." : "Save Changes"}{" "}
              </button>
              <button
                className="cancel"
                onClick={(e) => {
                  e.preventDefault();
                  setIsForm(false);
                }}
              >
                Cancel
              </button>
            </>
          )}
        </div>
      </form>
    </div>
  );
}
const Detail = ({
  name,
  title,
  value,
  isForm,
}: {
  title: string;
  value: string | boolean;
  isForm: boolean;
  name: string;
}) => {
  return (
    <div className="receipt-con__body__detail">
      <p className="receipt-con__body__detail__label">{title}</p>
      {isForm ? (
        <input defaultValue={value?.toString()} name={name} />
      ) : (
        <p className="receipt-con__body__detail__value">{value}</p>
      )}
    </div>
  );
};
export default Receipt;
