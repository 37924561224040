import moment from "moment";
import { StringifyOptions } from "querystring";
import React, { FormEvent, useState } from "react";
import Calendar from "react-calendar";
import { useSelector } from "react-redux";
import { RootState } from "../../../..";
import { api } from "../../../../utilities/core";
import Pagination from "../../../../utilities/Pagination";
import "./report.scss";
import Loader from "../../../../utilities/Loader";
function ProductReport() {
  const { entity } = useSelector((state: RootState) => state);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = 100;
  let skip = 0;
  const [id, setId] = useState("");
  const [timeInterval, setTimeInterval] = useState("None");
  const [transactionData, setTransactionData] = useState<{
    transactions: {
      _id: string;
      quantity: number;
      itemId: string;
      name: string;
      discount: number;
      sell_price: number;
      profit: number;
      total: number;
      createdAt: string;
    }[];
    totalDiscount: number;
    totalProfit: number;
    totalQuantity: number;
    totalSubTotal: number;
    total: number;
  }>();
  const [consumptionRate, setConsumptionRate] = useState<{
    amcr: number;
    awcr: number;
    adcr: number;
  }>({ amcr: 0, adcr: 0, awcr: 0 });
  let [date, setDate] = useState<{ startDate: string; endDate: string }>({
    startDate: moment().startOf("day").toISOString(),
    endDate: moment().endOf("day").toISOString(),
  });
  let [results, setResults] = useState<{ name: string; _id: string }[]>([]);
  let [query, setQuery] = useState("");
  let [fetchingProducts, setFetchingProducts] = useState(false);
  let handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setFetchingProducts(true);
      setResults([]);
      let req = await fetch(`${api}/product-options/${query}`, {
        headers: { Authorization: "Bearer " + entity?.token },
      });
      if (req.ok) {
        let res = await req.json();
        res.length <= 0 ? alert("No Product found") : setResults(res);
        setFetchingProducts(false);
        return;
      }

      throw new Error(await req.text());
    } catch (error: any) {
      console.log(error);
      alert(error.message);
    }
    setFetchingProducts(false);
  };

  const handleStartChange = (value: any) => {
    console.log(value);
    let startDate = moment(value[0]).startOf("day").format();
    let endDate = moment(value[1]).endOf("day").format();
    setDate({ startDate, endDate });
    if (id) handleFetchTransactions(id, startDate, endDate);
  };

  const handleEndChange = (value: any) => {
    setDate((state) => {
      return { ...state, endDate: value };
    });
  };

  const handleSelectProduct = (id: string) => {
    setResults([]);
    setId(id);
    handleFetchTransactions(id);
  };
  const handleFetchTransactions = async (
    _id: string = id,
    _startDate: string = date.startDate,
    _endDate: string = date.endDate,
    _interval: string = timeInterval,
    _limit: number = PageSize,
    _skip: number = skip
  ) => {
    try {
      setLoading(true);
      getConsuptionRate(_id);
      let req = await fetch(`${api}/product-transactions`, {
        body: JSON.stringify({
          _id,
          _startDate,
          _endDate,
          _limit,
          _skip,
          _interval,
        }),
        method: "POST",
        headers: {
          Authorization: "Bearer " + entity?.token,
          "Content-type": "application/json",
        },
      });
      if (req.ok) {
        let res = await req.json();
        setTransactionData(res);
        setTotalCount(res.count || 0);
        setLoading(false);
        return;
      }
      throw new Error(await req.text());
    } catch (error) {
      setLoading(false);
      alert(error);
    }
  };
  const getConsuptionRate = async (id: string) => {
    try {
      let req = await fetch(api + "/product-consumption-rate/" + id);
      if (req.ok) {
        let res = await req.json();
        setConsumptionRate(res);
      }
    } catch (error) {}
  };
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    handleFetchTransactions(
      id,
      date.startDate,
      date.endDate,
      timeInterval,
      PageSize,
      page - 1
    );
  };
  return (
    <div className="product-report-con">
      {loading && <Loader />}

      <p className="product-report-con__title">Product Report</p>
      <div className="product-report-con__body">
        <form
          onSubmit={handleSubmit}
          className="product-report-con__body__search-bar-con"
        >
          <input
            placeholder="Amlodipine"
            onFocus={() => setResults([])}
            onChange={(e) => setQuery(e.target.value)}
            autoFocus={true}
            autoComplete="off"
            autoCorrect="off"
            type="search"
            name=""
            id=""
            value={query}
            disabled={fetchingProducts}
          />
          <button disabled={fetchingProducts} type="submit">
            Search
          </button>
          {(fetchingProducts || results?.length > 0) && (
            <div className="product-report-con__body__search-bar-con__search-results">
              {fetchingProducts && (
                <div className="product-report-con__body__search-bar-con__search-results__loader">
                  <p>Loading...</p>
                </div>
              )}
              {results?.map((result) => (
                <div
                  key={result._id}
                  onClick={() => handleSelectProduct(result._id)}
                  className="product-report-con__body__search-bar-con__search-results__result"
                >
                  <p>{result.name}</p>
                </div>
              ))}
            </div>
          )}
        </form>
        <div className="product-report-con__body__main">
          <div className="product-report-con__body__main__calender-con">
            <div className="product-report-con__body__main__calender-con__calender">
              <Calendar selectRange={true} onChange={handleStartChange} />
            </div>
          </div>
          <div className="product-report-con__body__main__interval">
            <label htmlFor="">Set Interval</label>
            <select
              onChange={(e) => {
                setTimeInterval(e.target.value);
                if (id)
                  handleFetchTransactions(
                    id,
                    date.startDate,
                    date.endDate,
                    e.target.value
                  );
              }}
            >
              <option value="">None</option>
              <option value="hourly">Hourly</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </select>
          </div>

          <div className="product-report-con__body__main__table">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Discount</th>
                  <th>Sub-Total</th>
                  <th>Total</th>
                  <th>Profit</th>
                </tr>
              </thead>
              <tbody>
                <tr className="bottom">
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td>
                    {transactionData?.totalQuantity?.toLocaleString()}unit(s)
                  </td>
                  <td></td>
                  <td>
                    &#8358;{transactionData?.totalDiscount?.toLocaleString()}
                  </td>
                  <td>
                    &#8358;{transactionData?.totalSubTotal?.toLocaleString()}
                  </td>
                  <td>&#8358;{transactionData?.total?.toLocaleString()}</td>
                  <td>
                    &#8358;{transactionData?.totalProfit?.toLocaleString()}
                  </td>
                </tr>
                {transactionData?.transactions?.map(
                  (
                    {
                      createdAt,
                      name,
                      quantity,
                      discount,
                      total,
                      profit,
                      sell_price,
                    },
                    index
                  ) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        {moment
                          .utc(createdAt)
                          .format(
                            timeInterval === "hourly"
                              ? "DD/MM/YY hh:mm"
                              : timeInterval === "weekly"
                              ? "DD/MM/YY"
                              : timeInterval === "daily"
                              ? "DD/MM/YY"
                              : timeInterval === "monthly"
                              ? "MMM/YYYY"
                              : timeInterval === "yearly"
                              ? "YYYY"
                              : "DD/MM/YY hh:mm:ss"
                          )}
                      </td>
                      <td>{name}</td>
                      <td>{quantity}</td>
                      <td>{sell_price}</td>
                      <td>{discount}</td>
                      <td>{total + discount}</td>
                      <td>{total}</td>
                      <td>{profit * quantity - discount}</td>
                    </tr>
                  )
                )}
                <tr className="bottom">
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td>
                    {transactionData?.totalQuantity?.toLocaleString()}unit(s)
                  </td>
                  <td></td>
                  <td>
                    &#8358;{transactionData?.totalDiscount?.toLocaleString()}
                  </td>
                  <td>
                    &#8358;{transactionData?.totalSubTotal?.toLocaleString()}
                  </td>
                  <td>&#8358;{transactionData?.total?.toLocaleString()}</td>
                  <td>
                    &#8358;{transactionData?.totalProfit?.toLocaleString()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={PageSize}
            onPageChange={(page) => handlePageChange(page)}
          /> */}
          <div className="summary-con">
            <p>
              Average Daily Consumption Rate is{" "}
              {consumptionRate.adcr.toLocaleString()} unit(s) per day
            </p>
            <p>
              Average Weekly Consumption Rate is{" "}
              {consumptionRate.awcr.toLocaleString()} unit(s) per week
            </p>
            <p>
              Average Monthly Consumption Rate is{" "}
              {consumptionRate.amcr.toLocaleString()} unit(s) per month
            </p>
            <small>
              * These consumption rates are compiled from transactions of the
              last 3 months, hence should be followed with caution
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductReport;
