import React, { FormEvent, useRef, useState } from "react";
import {
  useLinkClickHandler,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { api } from "../../../../../utilities/core";
import "../supply.scss";
const Import = () => {
  let rawEntity = localStorage.getItem("entity");
  let [disabled, setDisabled] = useState(false);
  let inputRef = useRef<HTMLInputElement>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const entity = rawEntity ? JSON.parse(rawEntity) : null;
  const toBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleImportSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisabled(true);
    if (inputRef.current) {
      let file: File = inputRef.current.files!![0];
      console.log(JSON.stringify(file));
      const reader: ArrayBuffer | string | null = await toBase64(file);
      console.log(reader, 0);

      try {
        // eslint-disable-next-line no-unused-vars
        let req = await fetch(api + "/item", {
          method: "Post",
          body: reader,
          headers: {
            Authorization: "Bearer " + entity.token,
            "Content-type": "application/octet-stream",
          },
        });

        if (req.ok) {
          console.log(req.headers);
          let { count } = await req.json();
          alert(`${count} product(s) added successfully`);
        }
        if (req.status === 401) {
          navigate("/", { state: { path: location.pathname } });

          return;
        }
      } catch (error) {
        console.log(error);
        alert("failed");
      }
    }
    setDisabled(false);
  };

  return (
    <div className="import-con">
      <h1>Import Products from Excel file</h1>
      <form
        onSubmit={handleImportSubmit}
        action=""
        encType="multipart/form-data"
      >
        <input
          ref={inputRef}
          required
          type="file"
          accept=".xls,.xlsx"
          name="file"
        />
        <button disabled={disabled}>IMPORT</button>
      </form>
    </div>
  );
};

export default Import;
